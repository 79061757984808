<!--
 * @Descripttion: 足球
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2024-06-23 20:59:51
-->
<template>
  <div :class="outerStyle ? 'container_component' : 'h_100'"  v-loading="loading">
    <div :class="outerStyle ? 'always_box' : 'h_100' ">
      <div class="always_head">
        <headNav
          :navList="footballNavList"
          @selectNav="switchNavIndex"
        ></headNav>
      </div>
      <!-- 内容 -->
      <div ref="football" :class="outerStyle ? 'always_content' : 'outer_content'">
        <div class="content_table mt_15">
          <div>
            <el-table 
            :data="tableData" 
            border
            :height="tableHedr"
            row-key="id"
            style="width: 100%"
            :row-class-name="getRowClass"
            :expand-row-keys="expands"
            @expand-change="clickRowHandle"
            >
              <el-table-column type="expand" width="40">
                <template slot-scope="props">
                  <!-- 比分 -->
                  <div v-if="navIndex == 2" class="popup_score_info">
                    <div class="score_box df">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">胜</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          :class="scoreItem1.status == 1 ? 'single_f' : ''"
                          v-for="(scoreItem1, scoreIndex) in props.row.data[2]
                            .data[0].data"
                          :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row,scoreItem1)"
                          @click="onSingle(props.row, scoreItem1, '比分')"
                        >
                          <div class="score_text">{{ scoreItem1.key }}</div>
                          <div class="score_odds">{{ scoreItem1.value }}</div>
                        </el-button>
                        <el-button
                          class="gird_item cursor "
                          @dblclick.native="shishiClick(props.row, props.row.data[2].data[1].data[0])"
                          @click="
                            onSingle(
                              props.row,
                              props.row.data[2].data[1].data[0],
                              '比分'
                            )
                          "
                          :class="
                            props.row.data[2].data[1].data[0].status == 1
                              ? 'single_f'
                              : ''
                          "
                        >
                          <div class="score_text">
                            {{ props.row.data[2].data[1].data[0].key }}
                          </div>
                          <div class="score_odds">
                            {{ props.row.data[2].data[1].data[0].value }}
                          </div>
                        </el-button>
                      </div>
                    </div>
                    <div class="score_box df">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">平</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem2, scoreIndex) in props.row.data[2]
                            .data[2].data"
                          @click="onSingle(props.row, scoreItem2, '比分')"
                          @dblclick.native="shishiClick(props.row,scoreItem2)"
                          :key="scoreIndex"
                          :class="scoreItem2.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem2.key }}</div>
                          <div class="score_odds">{{ scoreItem2.value }}</div>
                        </el-button>
                        <el-button
                          class="gird_item cursor"
                          :class="
                            props.row.data[2].data[3].data[0].status == 1
                              ? 'single_f'
                              : ''
                          "
                          @dblclick.native="shishiClick(props.row,props.row.data[2].data[3].data[0])"
                          @click="
                            onSingle(
                              props.row,
                              props.row.data[2].data[3].data[0],
                              '比分'
                            )
                          "
                        >
                          <div class="score_text">
                            {{ props.row.data[2].data[3].data[0].key }}
                          </div>
                          <div class="score_odds">
                            {{ props.row.data[2].data[3].data[0].value }}
                          </div>
                        </el-button>
                      </div>
                    </div>
                    <div class="score_box df">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">负</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem3, scoreIndex) in props.row.data[2]
                            .data[4].data"
                          @click="onSingle(props.row, scoreItem3, '比分')"
                          @dblclick.native="shishiClick(props.row,scoreItem3)"
                          :key="scoreIndex"
                          :class="scoreItem3.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem3.key }}</div>
                          <div class="score_odds">{{ scoreItem3.value }}</div>
                        </el-button>
                        <el-button
                          class="gird_item cursor"
                          @click="
                            onSingle(
                              props.row,
                              props.row.data[2].data[5].data[0],
                              '比分'
                            )
                          "
                           @dblclick.native="shishiClick(props.row,props.row.data[2].data[5].data[0])"
                          :class="
                            props.row.data[2].data[5].data[0].status == 1
                              ? 'single_f'
                              : ''
                          "
                        >
                          <div class="score_text">
                            {{ props.row.data[2].data[5].data[0].key }}
                          </div>
                          <div class="score_odds">
                            {{ props.row.data[2].data[5].data[0].value }}
                          </div>
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <!-- 总进球数 -->
                  <div v-else-if="navIndex == 3" class="popup_score_info">
                    <div class="score_box df">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">总进球数</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                        @dblclick.native="shishiClick(props.row, scoreItem1)"
                          class="gird_item cursor"
                          v-for="(scoreItem1, scoreIndex) in props.row.data[3]
                            .data"
                          :key="scoreIndex"
                          @click="onSingle(props.row, scoreItem1, '总进球')"
                          :class="scoreItem1.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem1.key }}</div>
                          <div class="score_odds">{{ scoreItem1.value }}</div>
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <!-- 半全场 -->
                  <div v-else-if="navIndex == 4" class="popup_score_info">
                    <div class="score_box df">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">半全场</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem4, scoreIndex) in props.row.data[4]
                            .data"
                          :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row, scoreItem4)"
                          @click="onSingle(props.row, scoreItem4, '半全场')"
                          :class="scoreItem4.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem4.key }}</div>
                          <div class="score_odds">{{ scoreItem4.value }}</div>
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <!-- 混合过关 -->
                  <div v-else-if="navIndex == 5" class="popup_score_info">
                    <!-- <div class="score_box df">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">胜平负</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem1, scoreIndex) in props.row.data[0]
                            .data"
                          :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row, scoreItem1)"
                          @click="onSingle(props.row, scoreItem1, '胜平负')"
                          :class="scoreItem1.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem1.key }}</div>
                          <div class="score_odds">{{ scoreItem1.value }}</div>
                        </el-button>
                      </div>
                    </div> -->
                    <!-- <div class="score_box df mt_15">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">让胜平负</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem2, scoreIndex) in props.row.data[1]
                            .data"
                          :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row, scoreItem2)"
                          @click="onSingle(props.row, scoreItem2, '让胜平负')"
                          :class="scoreItem2.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem2.key }}</div>
                          <div class="score_odds">{{ scoreItem2.value }}</div>
                        </el-button>
                      </div>
                    </div> -->
                    <div class="mt_15">
                      <div class="score_box df">
                        <div class="score_title df_c m_r_15">
                          <div class="title_p">胜</div>
                        </div>
                        <div class="score_con df ">
                          <el-button
                            class="gird_item cursor"
                            v-for="(scoreItem1, scoreIndex) in props.row.data[2]
                              .data[0].data"
                            :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row, scoreItem1)"
                            @click="onSingle(props.row, scoreItem1, '比分')"
                            :class="scoreItem1.status == 1 ? 'single_f' : ''"
                          >
                            <div class="score_text">{{ scoreItem1.key }}</div>
                            <div class="score_odds">{{ scoreItem1.value }}</div>
                          </el-button>
                          <el-button
                            class="gird_item cursor"
                            @click="
                              onSingle(
                                props.row,
                                props.row.data[2].data[1].data[0],
                                '比分'
                              )
                            "
                          @dblclick.native="shishiClick(props.row, props.row.data[2].data[1].data[0])"
                            :class="
                              props.row.data[2].data[1].data[0].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            <div class="score_text">
                              {{ props.row.data[2].data[1].data[0].key }}
                            </div>
                            <div class="score_odds">
                              {{ props.row.data[2].data[1].data[0].value }}
                            </div>
                          </el-button>
                        </div>
                      </div>
                      <div class="score_box df">
                        <div class="score_title df_c m_r_15">
                          <div class="title_p">平</div>
                        </div>
                        <div class="score_con df ">
                          <el-button
                            class="gird_item cursor"
                            v-for="(scoreItem2, scoreIndex) in props.row.data[2]
                              .data[2].data"
                            :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row, scoreItem2)"
                            @click="onSingle(props.row, scoreItem2, '比分')"
                            :class="scoreItem2.status == 1 ? 'single_f' : ''"
                          >
                            <div class="score_text">{{ scoreItem2.key }}</div>
                            <div class="score_odds">{{ scoreItem2.value }}</div>
                          </el-button>
                          <el-button
                            class="gird_item cursor"
                            @dblclick.native="shishiClick(props.row,  props.row.data[2].data[3].data[0])"
                            @click="
                              onSingle(
                                props.row,
                                props.row.data[2].data[3].data[0],
                                '比分'
                              )
                            "
                            :class="
                              props.row.data[2].data[3].data[0].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            <div class="score_text">
                              {{ props.row.data[2].data[3].data[0].key }}
                            </div>
                            <div class="score_odds">
                              {{ props.row.data[2].data[3].data[0].value }}
                            </div>
                          </el-button>
                        </div>
                      </div>
                      <div class="score_box df">
                        <div class="score_title df_c m_r_15">
                          <div class="title_p">负</div>
                        </div>
                        <div class="score_con df ">
                          <el-button
                            class="gird_item cursor"
                            v-for="(scoreItem3, scoreIndex) in props.row.data[2]
                              .data[4].data"
                            :key="scoreIndex"
                            @dblclick.native="shishiClick(props.row, scoreItem3)"
                            @click="onSingle(props.row, scoreItem3, '比分')"
                            :class="scoreItem3.status == 1 ? 'single_f' : ''"
                          >
                            <div class="score_text">{{ scoreItem3.key }}</div>
                            <div class="score_odds">{{ scoreItem3.value }}</div>
                          </el-button>
                          <el-button
                            class="gird_item cursor"
                            @dblclick.native="shishiClick(props.row, props.row.data[2].data[5].data[0])"
                            @click="
                              onSingle(
                                props.row,
                                props.row.data[2].data[5].data[0],
                                '比分'
                              )
                            "
                            :class="
                              props.row.data[2].data[5].data[0].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            <div class="score_text">
                              {{ props.row.data[2].data[5].data[0].key }}
                            </div>
                            <div class="score_odds">
                              {{ props.row.data[2].data[5].data[0].value }}
                            </div>
                          </el-button>
                        </div>
                      </div>
                    </div>

                    <div class="score_box df mt_15">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">总进球数</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem1, scoreIndex) in props.row.data[3]
                            .data"
                          :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row,scoreItem1)"
                          @click="onSingle(props.row, scoreItem1, '总进球')"
                          :class="scoreItem1.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem1.key }}</div>
                          <div class="score_odds">{{ scoreItem1.value }}</div>
                        </el-button>
                      </div>
                    </div>

                    <div class="score_box df mt_15">
                      <div class="score_title df_c m_r_15">
                        <div class="title_p">半全场</div>
                      </div>
                      <div class="score_con df ">
                        <el-button
                          class="gird_item cursor"
                          v-for="(scoreItem4, scoreIndex) in props.row.data[4]
                            .data"
                          :key="scoreIndex"
                          @dblclick.native="shishiClick(props.row,scoreItem4)"
                          @click="onSingle(props.row, scoreItem4, '半全场')"
                          :class="scoreItem4.status == 1 ? 'single_f' : ''"
                        >
                          <div class="score_text">{{ scoreItem4.key }}</div>
                          <div class="score_odds">{{ scoreItem4.value }}</div>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="编号" width="80">
                <template slot-scope="scope">
                  <div>{{ scope.row.week }}{{ scope.row.issue_num }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="comp" label="赛事" width="150">
                <template slot-scope="scope">
                  <div class="table_game">
                    {{ scope.row.comp }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="home" label="主队vs客队">
                <template slot-scope="scope">
                  <div class="df_c">
                    <div>
                      {{ scope.row.home }} <span class="vs_text">VS</span>
                      {{ scope.row.away }}
                    </div>
                    <div class="one_text" v-if="navIndex == 2 || navIndex == 3 || navIndex == 4">单</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="time" label="开赛时间" width="150">
              </el-table-column>
              <el-table-column label="让球" 
              :key="Math.random()"
              width="120" v-if="navIndex == 1 || navIndex == 5">
                <template slot-scope="scope">
                  <div class="ball_box">
                    <div class="ball_box_item">0</div>
                    <div class="ball_box_item mt_5">
                      {{ scope.row.data[1].num }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="胜" width="120" 
              :key="Math.random()"
              v-if="navIndex == 1 || navIndex == 5">
                <template slot-scope="scope">
                  <div class="score_box">
                   
                    <el-button
                      v-if="scope.row.data[0].data"
                      class="score_box_item cursor"
                      @click="
                        onSingle(scope.row, scope.row.data[0].data[0], '胜平负')
                      "
                      @dblclick.native="shishiClick(scope.row,scope.row.data[0].data[0])"
                      :class="
                        scope.row.data[0].data[0].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[0].data[0].value }}

                      <div class="checked_div"
                      v-if="scope.row.data[0].data[0].single == 0"
                      >
                    </div>
                    </el-button>
                    <el-button
                    class="score_box_item cursor"
                      v-else
                    >
                      --
                    </el-button>
                    <el-button
                      class="score_box_item cursor mt_5"
                      @dblclick.native="shishiClick(scope.row,scope.row.data[1].data[0])"
                      @click="
                        onSingle(
                          scope.row,
                          scope.row.data[1].data[0],
                          '让胜平负'
                        )
                      "
                      :class="
                        scope.row.data[1].data[0].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[1].data[0].value }}
                      <div class="checked_div"
                      v-if="scope.row.data[1].data[0].single == 0"
                      >
                    </div>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="平" 
              :key="Math.random()"
              width="120" v-if="navIndex == 1 || navIndex == 5">
                <template slot-scope="scope">
                  <div class="score_box">
                   
                    <el-button
                      v-if="scope.row.data[0].data"
                      class="score_box_item cursor"
                      @dblclick.native="shishiClick(scope.row, scope.row.data[0].data[1])"
                      @click="
                        onSingle(scope.row, scope.row.data[0].data[1], '胜平负')
                      "
                      :class="
                        scope.row.data[0].data[1].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[0].data[1].value }}
                      <div class="checked_div"
                    v-if="scope.row.data[0].data[0].single == 0"
                      >
                    </div>
                    </el-button>
                    <el-button
                      class="score_box_item cursor"
                      v-else
                    >
                      --
                    </el-button>
                    <el-button
                      class="score_box_item cursor mt_5"
                      @dblclick.native="shishiClick(scope.row,scope.row.data[1].data[1])"
                      @click="
                        onSingle(
                          scope.row,
                          scope.row.data[1].data[1],
                          '让胜平负'
                        )
                      "
                      :class="
                        scope.row.data[1].data[1].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[1].data[1].value }}
                      <div class="checked_div"
                       v-if="scope.row.data[1].data[1].single == 0"
                        >
                      </div>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="负" width="120" 
              :key="Math.random()"
              v-if="navIndex == 1 || navIndex == 5">
                <template slot-scope="scope">
                  <div class="score_box">
                   
                    <el-button
                      v-if="scope.row.data[0].data"
                      class="score_box_item cursor"
                      @dblclick.native="shishiClick(scope.row, scope.row.data[0].data[2])"
                      @click="
                        onSingle(scope.row, scope.row.data[0].data[2], '胜平负')
                      "
                      :class="
                        scope.row.data[0].data[2].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[0].data[2].value }}
                      <div class="checked_div"
                      v-if="scope.row.data[0].data[0].single == 0"
                        >
                      </div>
                    </el-button>
                    <el-button
                      class="score_box_item cursor"
                      v-else
                    >
                      --
                    </el-button>
                    <el-button
                      class="score_box_item cursor mt_5"
                      @dblclick.native="shishiClick(scope.row, scope.row.data[1].data[2])"
                      @click="
                        onSingle(
                          scope.row,
                          scope.row.data[1].data[2],
                          '让胜平负'
                        )
                      "
                      :class="
                        scope.row.data[1].data[2].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[1].data[2].value }}
                      <div class="checked_div"
                        v-if="scope.row.data[1].data[2].single == 0"
                      >
                      </div>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="玩法" width="120" 
              :key="Math.random()"
              v-if="navIndex != 1">
                <template slot-scope="scope">
                  <div class="score_box">
                    <el-button v-if="scope.row.expandsStatus == 0" type="primary" @click="clickRowHandle(scope.row)">展开</el-button>
                    <el-button v-else @click="clickRowHandle(scope.row)">收起</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!--底部 -->
      <div class="always_bottom">
        <div class="always_bottom_title df_c_s">
          <div class="left df_c">
            <div class="mr_15">
              已选 <span class="color_red">{{ sessionNum }}</span> 场
            </div>
            <div class="multiple">
              <div class="m_r_10">倍数:</div>
              <div class="m_r_10">
                <el-input-number
                  size="mini"
                  v-model="dataNumber"
                  @change="valChange"
                  :min="1"
                  :max="50"
                  label="描述文字"
                >
                </el-input-number>
              </div>
              <div>[最高50倍]</div>
            </div>
          </div>
          <div class="right df_c">
            <div class="m_r_10">客户名称:</div>
            <div class="right_input m_r_10">
              <el-input
                size="mini"
                width="80px"
                class="m_r_10"
                v-model="customerName"
                placeholder="请输入客户名称"
              ></el-input>
            </div>
            <div class="m_r_10">打印份数:</div>
            <div class="m_r_10">
              <el-input-number
                size="mini"
                v-model="printNum"
                @change="handleChange"
                :min="1"
                :max="2"
                label="描述文字"
              >
              </el-input-number>
            </div>
            <el-button size="mini" 
            @click="onSubmit"
            :disabled="!isCommit()"
            type="danger">提交</el-button>
          </div>
        </div>
        <div class="always_bottom_way mt_15 df_s">
          <div class="left df">
            <div class="m_r_15">过关方式：</div>
            <div>
              <div class="way_radio">
                <el-radio v-model="isComplex" label="1">自由过关</el-radio>
                <el-radio
                  v-if="this.sellingNumber > 2"
                  v-model="isComplex"
                  label="2"
                  >M串N</el-radio
                >
              </div>
              <!--    自由过关 -->
              <div v-if="isComplex == 1">
                <div class="single df" v-if="singleList.length > 0">
                  <div
                    class=""
                    v-for="(singleItem, singleIndex) in singleList"
                    :key="singleIndex"
                    @click="selectMake(singleItem, singleIndex)"
                  >
                    <div 
                        v-if="small <= singleIndex"
                        :class="singleItem.status == 1 ? 'select_item' : ''"
                        class="single_item m_r_15 cursor">
                            {{ singleItem.showVal }}
                        </div>
                </div>
                </div>
              </div>
              <div v-else>
                <div class="single df" v-if="multipleList.length > 0">
                  <div
                    class="single_item m_r_15 cursor"
                    v-for="(multipleItem, multipleIndex) in multipleList"
                    :key="multipleIndex"
                    :class="multipleItem.status == 1 ? 'select_item' : ''"
                    @click="selectMultiple(multipleItem, multipleIndex)"
                  >
                    {{ multipleItem.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div>
              投注金额：<span class="color_red">{{ price }}</span
              >元
            </div>
            <div>
              理论最高奖金：<span class="color_red">
                <!-- <span v-if="minPrice > 0">{{ minPrice }} ～</span> -->
                {{
                  purchase_amount > 500000000 ? "5,000,000,00" : purchase_amount
                }} </span
              >元
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="修改赔率"
      :visible.sync="shishiShow"
      width="380px"
      center>
        <div>
          <el-form ref="form" :model="oddsForm" label-width="80px">
            <el-form-item label="类型：">
              <div class="odds_key">{{ newOddsForm.key }}</div>
            </el-form-item>
            <el-form-item label="旧赔率：">
              <el-input v-model="oddsForm.oldOdds"
              :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="新赔率：">
              <el-input type="Number" v-model="oddsForm.newOdds"></el-input>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="m_r_15" @click="shishiShow = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateOdds">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="打印预览"
      :visible.sync="printShow"
      width="35%"
      center>
      <div style="">
        <footballPrint :printHtml="printHtml" ref="printDom1"></footballPrint>
      </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="printShow = false">取 消</el-button>
        <el-button type="primary" >确 定</el-button>
      </span>
    </el-dialog>

    <div v-html="printHtml"  ref="print">
    </div>
  </div>
</template>
<script>
import headNav from "@/components/navMenu";
import { 
  getRealtumefootball,
  getPreviousJz,
  getCodingGain,
  contestLotterys
} from "@/utils/api.js";   // 接口api
import footballPrint from "../../components/print/footballPrint.vue";
import {
  calcDescartes,
  deepfilterArr,
  splitArray,
  getNowTime
} from "@/components/package/unit.js";
import {
  minCal,
  calculate,
  getPay,
  sortdatalist,
} from "@/components/package/opt.js";
import multipleData from "@/components/package/multipleData.js";
var time = null;  //  在这里定义time 为null
export default {
  props: {
    outerStyle:  {   // false 往期数据
      type: Boolean,
      default: true
    },   
    date: {
      type: String,
      default: ''
    }
  },
  components: {
    headNav,
    footballPrint
  },
  mane: "football",
  data() {
    return {
      footballNavList: [
        {
          name: "胜平负/让胜平负",
          num: "1",
          gameType: "1",
          code: 0,
        },
        {
          name: "比分",
          num: "2",
          gameType: "2",
          code: 1,
        },
        {
          name: "总进球数",
          num: "3",
          gameType: "3",
          code: 2,
        },
        {
          name: "半全场胜平负",
          num: "4",
          gameType: "4",
          code: 3,
        },
        {
          name: "混合过关",
          num: "5",
          gameType: "5",
          code: 4,
        },
      ],
      gameType: 1,
      placeFraction: 0,
      selectIndex: 0,
      navIndex: 1,
      printNum: 1, // 打印的份数
      customerName: "", //  客户名称
      tableData: [],
      loseList: [], // 胜平负
      scoreList: [], // 比分的数据
      sellingList: [],
      lotteryList: [], // 筛选出选中的场次
      sellingNumber: 0, // 场次
      sessionNum: 0,    // 显示出来的场次
      total: 0, // 选中的场次
      singleList: [], // 自由过关
      selectMakeList: [], // 选中的自由过关组合
      selectMultipleShow: false,
      multipleList: [], // M串N
      selectMakeList: [], // 选中M串Nde shi
      isComplex: "1", // 过关方式  1:自由过关  2.M串N
      multipleShow: true,
      dataNumber: 1, //  下单倍数
      notPerice: 2, //  订单单价，固定两元
      odds: 1, //  订单税值  北单：0.65  其他1
      optimizeList: [], //  金额数组
      note: 0, // 有多少注
      price: 0, // 金额
      minPrice: 0, // 最小金额
      purchase_amount: 0, // 最大金额
      isRemoval: false,
      minList: [],
      sendList: [],
      blendCode: 1, //1.足球 2.篮球
      target: {},
      small: 1,// 是否显示单关
      printShow: false,    // 是否显示打印
      shishiShow: false,
      maximumBonus: 0,
      oddsForm: {//更改新旧
        oldOdds: '',
        newOdds: ''
      },
      selectText: '',
      newOddsForm: {},
      selectedValue: '',
      printHtml: '',
      printUrl: '',
      printForm: {},          // 打印信息
      loading: false,
       // 获取row的key值
       getRowKeys(row) {
        return row.id;
       },
      expands: [],    // 要展开的行，数值的元素是row的key值
      dataId: '',
      dataKey: '',
      reachType: '自由过关',
      afterLottery: [],    // 提交的玩法数据结构
      tableHedr: 0
    };
  },
  mounted() {
    if(this.outerStyle) {
      this.getTableData();
    }else {
      this.getPeriodsForm(this.date)
    }
    this.customerName = localStorage.getItem('userName')
   
    this.$nextTick(() => {
     const el = this.$refs.football
      const height = el.offsetHeight
      this.tableHedr = height - 15
    })
  },
  methods: {
    getRowClass() {
      if (this.navIndex == 1) {
        return 'row-expand-cover'
      }else {
        return 'row-expand-code'
      }
    },

    getPeriodsForm(date) {
      this.loading = true
      let that = this;
      this.tableData = []
      getPreviousJz({
        date,
        type: 1
      }).then(res => {
        if (res.code == 200) {
          that.loading = false
          that.tableData = res.data;
         
        }else {
          that.loading = false
        }
      })
      getCodingGain({
        type: 1,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          that.printForm = res.data
        }
      })
    },

    // 查询足球数据
    getTableData() {
      this.loading = true
      let that = this;
      getRealtumefootball({}).then((res) => {
        if (res.code == 200) {
          that.loading = false
          that.tableData = res.data;
        }else {
          that.loading = false
        }
      });
      getCodingGain({
        type: 1,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          that.printForm = res.data
        }
      })
    },
    // 导航选择
    switchNavIndex(index) {
      this.gameType = this.footballNavList[Number(index) - 1].gameType
      this.navIndex = index;
      this.placeFraction = 0
      this.selectIndex =  Number(index)  - 1
      /* 半全场，比分4 总进球 6 */
      if (this.selectIndex == 1 || this.selectIndex == 3) {
        this.small = 0
        this.placeFraction = 4
      } else if (this.selectIndex == 2) {
        this.small = 0
        this.placeFraction = 6
      }
      if(this.selectIndex == 1 || this.selectIndex == 4) {
        this.expands = []
        this.tableData.forEach( (tableItem, tableIndex) => {
            if(tableIndex == 0) {
              this.$set(tableItem, 'expandsStatus', 1)
              this.expands.push(tableItem.id);
            }else {
              this.$set(tableItem, 'expandsStatus', 0)
            }
          })
      }else if(this.selectIndex == 2 || this.selectIndex == 3) {
        this.tableData.forEach( (tableItem, tableIndex) => {
          this.$set(tableItem, 'expandsStatus', 1)
            this.expands.push(tableItem.id);
          })
      }


      

      this.$nextTick(() => {
        this.cleanSoccerVal()
      })
    },
    // 倍数
    async valChange(e) {
        await this.countAmount(this.notPerice, this.odds, e)
        // this.setFilteCode()
    },
    // 打印
    handleChange() {},
    // 双击修改赔率
    shishiClick(dataItem, item) {
      // 往期的数据才能修改
      if(this.outerStyle == true) {
        return
      }
      clearTimeout(time);  //首先清除计时器
      this.shishiShow = true
      this.oddsForm.oldOdds = item.value
      this.oddsForm.newOdds = item.value
      this.newOddsForm = item

      this.dataId = dataItem.id
      this.dataKey = item.key
    },
    // 确定修改赔率
    async onUpdateOdds() {
      this.newOddsForm.value = this.oddsForm.newOdds
      this.shishiShow = false
      // 修改计算值的赔率   需要场次的id以及玩法的key进行判定
      this.sellingList.forEach(sellingItem => {
        if(sellingItem.id == this.dataId && this.dataKey == sellingItem.key) {
          sellingItem.value = this.oddsForm.newOdds
        }
      })
      await this.childclick(this.notPerice, this.odds, this.dataNumber)
    },
    //
    async onSingle(dataItem, item, title) {
      clearTimeout(time);  //首先清除计时器
      time = setTimeout(async () => {
        if (item.status == 0) {
          let backFlag = this.isBackEnd(dataItem);
          if (this.sessionNum == 8 && !backFlag) {
            this.$message({
              type: "error",
              message: `最多只能选择8场比赛！`,
            });
            return;
          }

          this.total = this.total + 1;
          // dataItem.selectNum = dataItem.selectNum + 1
          item.status = 1;
          await this.setSelling(dataItem, item, title);
        } else {
          this.total = this.total - 1;
          // dataItem.selectNum = dataItem.selectNum - 1
          item.status = 0;
          let sellingIndex = 0;
          this.sellingList.forEach((sellingItem, index) => {
            if (
              sellingItem.value == item.value &&
              sellingItem.id == dataItem.id &&
              sellingItem.title == title
            ) {
              sellingIndex = index;
            }
          });
          this.deleteSelling(sellingIndex);
        }
        /* 判断场次 */
				// this.bySelling()
      }, 300);   //大概时间300ms
    },
    // 取消选中状态
    async deleteSelling(index) {
      this.sellingList.splice(index, 1);
      await this.bySelling();
      await this.childclick();
    },
    /* 判断是否是已经选过的 */
    isBackEnd(codeItem) {
      let flag = false;
      this.sellingList.forEach((sellingItem) => {
        if (sellingItem.id == codeItem.id) {
          flag = true;
        }
      });
      return flag;
    },
    async setSelling(dataItem, item, title) {
      let num = "";
      if (
        item.key == "让胜" ||
        item.key == "让负" ||
        item.key == "让平" ||
        item.key == "让分主胜" ||
        item.key == "让分客胜"
      ) {
        num = dataItem.data[1].num;
      }
      let data = {
        id: dataItem.id, //id
        comp: dataItem.comp,
        home: dataItem.home,
        away: dataItem.away,
        short_comp: dataItem.short_comp, //赛事简称
        short_home: dataItem.short_home, //主队简称
        short_away: dataItem.short_away, //客队简称
        issue_num: dataItem.issue_num, //序号
        match_time: dataItem.match_time, //比赛时间
        title: title,
        key: item.key,
        value: item.value,
        single: item.single,
        week: dataItem.week,
        name: item.name,
        code: item.code,
        rq: dataItem.rq,
        num: num, // 让球
      };
      this.sellingList.push(data);
      await this.bySelling();
      await this.childclick();
    },
    /* 统计选中的值 */
    bySelling() {
      var newArr = [];
      let obj = {};
      for (let i = 0; i < this.sellingList.length; i++) {
        //将arr[i].id作为对象属性进行判断
        if (!obj[this.sellingList[i].id]) {
          newArr.push(this.sellingList[i]);
          obj[this.sellingList[i].id] = true;
        }
      }
      this.lotteryList = newArr;
      this.sellingNumber = newArr.length;
      this.sessionNum = newArr.length;
      this.isCommit()
    },
    /* 判断是否有条件提交 */
    isCommit() {
      let flag = false;
      if (this.sellingNumber > 0) {
        if (this.selectIndex == 0 || this.selectIndex == 4) {
          if (this.sellingNumber == 1) {
            // 单关不能设置为零
            // this.purchase_amount = 0
            // this.price = 0
            flag = false;
          } else {
            flag = true;
          }
        } else {
          flag = true;
        }
        if (this.sellingNumber < 2) {
          let coflag = false;
          this.sellingList.forEach((sellingItem) => {
            if (sellingItem.single == 0) {
              coflag = true;
            }
          });
          if (coflag) {
            flag = true;
            this.small = 0;
            this.multipleShow = false;
          }
        } else {
          // this.small = 1;
          this.multipleShow = true;
        }
      }
      return flag;
    },
    // 统一调用算法入口
    async childclick() {
      await this.setBeidanLists()
      if (this.sellingNumber == 0) {
        this.singleList = [];
        this.purchase_amount = 0
        this.price = 0
      }
      if(!this.isCommit()) {
        this.singleList = [];
        this.purchase_amount = 0
        this.price = 0
        return 
      }
      // if(this.filterScore(this.sellingList) == false && this.sellingNumber == 1) {
      //   this.singleList = [];
      //   this.purchase_amount = 0
      //   this.price = 0
      //   return 
      // }
      // 初始化多串选择
      this.isComplex = "1";
      this.selectMakeList = [];
      let makeItem = {};
      this.lotteryList.sort(sortdatalist('id'))
      // 是否选中比赛

      if(this.navIndex == 2) {
        if(this.sellingNumber > 4) {
          this.sellingNumber = 4
        }
      }

      if(this.selectIndex == 4){
        if(this.sellingNumber > 4) {
          this.filterScore(this.sellingList)
        }
      }
      if(this.navIndex == 3) {
        if(this.sellingNumber > 6) {
          this.sellingNumber = 6
        }
      }
      if(this.navIndex == 4) {
        if(this.sellingNumber > 4) {
          this.sellingNumber = 4
        }
      }
        await this.countNumber(this.sellingNumber);
        this.$nextTick(() => {
          this.singleList[this.sellingNumber - 1].status = 1;
        });

        //默认设置一个多串过关的选项
        makeItem = this.singleList[this.sellingNumber - 1];
        this.selectMakeList.push(makeItem);
        if (this.sellingNumber > 2) {
          this.setMultiple(this.sellingNumber);
        }
        await this.countMinCal();
        await this.countSingle();
        let scoreGoal = await this.filterGoal(this.sellingList)
        if(scoreGoal) {
            this.small = 0
        }else {
            this.small = 1
        }
        await this.countAmount(this.notPerice, this.odds, this.dataNumber);
        this.setTimeoutList();
    },
    // 筛选是否全是比分类型的
			filterScore(list) {
				let codeStu = false
        let allStu = false
				list.forEach(item => {
					if (item.title == '比分' || item.title == '半全场' ) {
						codeStu = true
					}else if (item.title == '总进球' ) {
            allStu = true
          }
				})
        if (codeStu) {
          this.sellingNumber = 4
        }

        if(allStu) {
          if(this.sellingNumber > 6) {
            this.sellingNumber = 6
          }
        }
			},
    setBeidanLists() {
        let newArr = []
        //先遍历取出搜索的key（因为要根据不同的key生成数组）
        let keys = this.sellingList.map((val) => {
            return val.id;
        });
        //数组去重，这样就得到了一共有多少个key
        keys = [...new Set(keys)];
        //声明一个target来装数组
        let target = {};
        //将target对象的每一项设成数组格式
        keys.forEach((val) => {
            target[val] = [];
        });
        //如果key值相等，则push到同一个数组
        this.sellingList.map((val) => {
            keys.some((value) => {
                if (val.id == value) {
                    target[value].push(val);
                    return;
                }
            });
        });
        this.target = target
    },
    /* 单串 */
    async countNumber(val) {
      this.singleList = [];
      if (val > 0) {
        for (let i = 0; i < val; i++) {
          let data = {
            name: "",
            value: 0,
            status: "",
            selectVal: ''
          };
          if (i == 0) {
            data.name = "单关";
            data.value = 1;
            data.status = 0;
            data.selectVal = '单关'
            data.showVal = '单关'
          } else {
            data.name = i + 1 + "串" + "1";
            data.value = i + 1;
            data.status = 0;
            data.selectVal = `${i + 1}x1`
            data.showVal = `${i + 1}关`
          }
          this.singleList.push(data);
        }
      }
      /* 计算金额 */
    },
    /* 是否有多串数据 */
    setMultiple(num) {
      this.multipleList = [];
      if (this.multipleShow) {
        let list = multipleData.multipleList;
        list.map((item) => {
          if (item.len == num) {
            this.multipleLen = item.len;
            this.multipleList = item.list;
          }
        });
      }
    },
    // 选中M串N
    selectMultiple(item, index) {
      this.clearMultipleStatus();
      item.status = 1;
      if (this.selectMakeList.length > 0) {
        this.clearSingle();
      }
      /* 更改状态 */
      if (this.selectMultipleShow == false) {
        this.selectMultipleShow = true;
      }
      this.selectText = item.selectVal;
      /* 清空数据 */
      this.selectMakeList = [];
      this.addMakeList(item.keys);
    },
    addMakeList(arr) {
      arr.forEach((num) => {
        this.singleList.forEach((singItem, index) => {
          if (num == index) {
            this.selectMakeList.push(singItem);
          }
        });
      });
      this.countAmount(this.notPerice, this.odds, this.dataNumber);
      this.setTimeoutList();
    },
    /* 清除自由过关的状态 */
    clearSingle() {
      this.selectMakeList = [];
      this.singleList.forEach((item) => {
        if (item.status == 1) {
          item.status = 0;
        }
      });
    },
    // 自由过关
    selectMake(item, index) {
      if (this.selectMultipleShow == true) {
        this.clearMultipleStatus();
        this.selectMakeList = [];
        this.selectMultipleShow = false;
      }
      if (item.status == 0) {
        item.status = 1;
        this.selectMakeList.push(item);
      } else {
        if (this.selectMakeList.length == 1 && item.status == 1) {
          this.$message.error("至少选择一种过关方式");
        } else {
          item.status = 0;
          this.selectMakeList.splice(index, 1);
        }
      }
      this.selectMakeList = this.singleList.filter((item) => {
        return item.status == 1;
      });
      this.countAmount(this.notPerice, this.odds, this.dataNumber);
      this.setTimeoutList();
    },
    /* 计算值 */
    async setTimeoutList() {
      this.isRemoval = true;
      let arrangeList = [];
      let filteList = [];
      this.selectMakeList.forEach((seleItem) => {
        let count = minCal(this.sellingList, seleItem.value, "ang");
        arrangeList.push(count);
      });

      this.selectMakeList.forEach((seleItem) => {
        let count = minCal(this.sellingList, seleItem.value, "fil");
        filteList.push(count);
      });
      this.filteList = filteList;
      this.arrangeList = arrangeList;
      this.setFilteCode();
    },
    setFilteCode() {
      let codeList = [];
      let lotteryLen = this.lotteryList.length;
      this.filteList.forEach((arrItem, arrIndex) => {
        arrItem.forEach((codeItem, codeIndex) => {
          let data = {
            mode: lotteryLen == 1 ? "单关" : `${arrItem[0].length}串1`,
            multiple: this.dataNumber,
            bonus: this.setMoney(arrIndex, codeIndex, "filte"),
            odds: codeItem,
          };
          codeList.push(data);
        });
      });
      this.afterLottery = codeList;
    },
    /* 清除（M串N）状态 */
    clearMultipleStatus() {
      this.multipleList.map((item) => {
        if (item.status == 1) {
          item.status = 0;
        }
      });
    },
    async countAmount(notPerice, odds, number) {
      this.optimizeList = [];
      let selectLen = this.selectMakeList.length;
      let newMoney = 0;
      let newNote = 0;
      let minList = [];
      let makeName = ''
      await this.selectMakeList.forEach((seleItem) => {
        let count = [];
        this.minList.forEach((minItem) => {
          if (minItem.key == seleItem.value) {
            count = minItem.list;
          }
        });
        this.optimizeList.push(count);
        newNote = newNote + count.length;
        let setMoney = 0;
        let newMoneyArr = [];
        if (seleItem.name == "单关") {
          makeName = '单关'
          let dan = [];
          count.forEach((counts) => {
            if (this.ifType(counts)) {
              dan.push(this.maxMoney(counts));
            } else {
              dan.push([counts]);
            }
          });
          let code = 0;
          dan.forEach((item) => {
            let money = item * notPerice;
            code = money + code;
          });
          setMoney = code * number * odds;
          if (count.length > 1) {
            // 单关多场比赛需要计算最低预测奖金
            let minMoneys = [];
            count.forEach((countItem) => {
              let money = 1;
              countItem.forEach((sonItem) => {
                money = sonItem * notPerice * number * odds;
                minMoneys.push(money.toFixed(3));
              });
            });
            this.minPrice = this.minMoney(minMoneys);
          } else {
            this.minPrice = 0;
          }
        } else {
          makeName = '多串'
          if (count.length == 1) {
            count.forEach((counts) => {
              let money = 1;
              counts.forEach((item) => {
                money = item * money;
              });
              setMoney = money * notPerice * number * odds;
            });
          } else {
            count.forEach((counts) => {
              let card = 1;
              counts.forEach((item) => {
                card = card * item;
              });
              /* 获取最小金额 */
              let minPrice = card * notPerice * number * odds;
              minList.push(minPrice);
              setMoney = setMoney + card;
            });
            setMoney = setMoney * notPerice * number * odds;
          }
        }
        newMoney = newMoney + setMoney;
      });
      if(makeName == '单关') {
        this.note = newNote
        this.price = this.note * this.notPerice * number; // 多少钱
        // this.purchase_amount = newMoney.toFixed(2)       // 暂时禁用
        this.countBeidan(notPerice, odds, number);

      }else {
        this.note = newNote; // 多少注
        this.price = this.note * this.notPerice * number; // 多少钱

        if (this.multipleShow && minList.length > 1) {
          this.minPrice = this.minMoney(minList).toFixed(3);
        }
        // 去计算奖金
        this.countBeidan(notPerice, odds, number);
      }
    },
    /* 判断类型 */
    ifType(type) {
      let flat = false;
      if (type instanceof Array) {
        flat = true;
      } else {
        flat = false;
      }
      return flat;
    },
    /* 过滤最大值 */
    maxMoney(moneyList) {
      let MaxArrp = Math.max(...moneyList);
      return MaxArrp;
    },
    /* 过滤最小值 */
    minMoney(moneyList) {
      let MinArrp = Math.min(...moneyList);
      return MinArrp;
    },
    /* 计算预测奖金 */
    async countBeidan(notPerice, odds, number) {
      this.isRemoval = false;
      let maximumBonus = 0; // 最大金额
      let countMaxs = [];
      const numList = [];
      this.selectMakeList.forEach((seleItem) => {
        this.sendList.forEach((sendItem) => {
          if (seleItem.value == sendItem.key) {
            let newData = {
              key: sendItem.key,
              num: sendItem.list[1],
            };
            numList.push(newData);
            countMaxs.push(sendItem.list[1]);
          }
        });
      });
      /* 统计注数和最大金额 */
      countMaxs.forEach((maxItem) => {
        // this.note += sendItem[0]
        maximumBonus += maxItem;
      });
      this.purchase_amount = (
        maximumBonus.toFixed(3) *
        notPerice *
        odds *
        number
      ).toFixed(3);
      // await this.purchaseRule(this.lotteryList.length, this.purchase_amount)
      await this.setSignList(notPerice, odds, number);
    },
    async setSignList(notPerice, odds, number) {
      let list = await this.maximum();
      let arrangeList = [];
      this.selectMakeList.forEach((seleItem) => {
        let count = this.countMoney(list, seleItem.value);
        arrangeList.push(count);
      });
      splitArray(arrangeList, notPerice, odds, number, "bask", this);
    },
    countMoney(a, min) {
      return deepfilterArr(a, min, this.isRemoval);
    },
    countMinCal() {
      let minList = [];
      this.singleList.forEach((leItem) => {
        let count = minCal(this.sellingList, leItem.value);
        let data = {
          key: leItem.value,
          list: count,
        };
        minList.push(data);
      });
      this.minList = minList;
    },
    /* 判断是否开启单关 */
    filterGoal(list) {
        let codeStu = true
        list.forEach(item => {
            if (item.title == '让胜平负') {
                codeStu = false
            }
            if(item.title == '胜平负') {
                if(item.single == 1) {
                    codeStu = false
                }
            }
        })
        return codeStu
    },
    // 计算奖金
    setMoney(arrIndex, codeIndex, type) {
      let lotteryLen = this.lotteryList.length;
      let codeNum = 0;
      if (lotteryLen > 1) {
        let money = 1;
        this.optimizeList[arrIndex].forEach((optimizeItem, index) => {
          if (index == codeIndex) {
            optimizeItem.forEach((item) => {
              money = item * money;
            });
          }
        });
        let code = "";
        if (type == "data") {
          code = money.toFixed(3) * this.notPerice;
        } else {
          code =
            money.toFixed(2) * this.notPerice * this.odds * this.dataNumber;
        }

        codeNum = code.toFixed(3);
      } else {
        codeNum = (
          this.optimizeList[0][codeIndex] *
          this.notPerice *
          this.odds *
          this.dataNumber
        ).toFixed(3);
      }
      return codeNum;
    },
    /* 先统计所有的组合和最高奖金 */
    async countSingle() {
      let codeList = await this.retoolLotter();
      let calList = [];
      this.singleList.forEach((leItem) => {
        let count = calculate(codeList, leItem.value);
        let data = {
          key: leItem.value,
          list: count,
        };
        calList.push(data);
      });
      let sendList = [];
      calList.forEach((calItem) => {
        let send = getPay(calItem.list, calItem.key);
        let data = {
          key: calItem.key,
          list: send,
        };
        sendList.push(data);
      });
      this.sendList = sendList;
    },
    /* 重组数组 */
    retoolLotter() {
      let newArr = [];
      this.lotteryList.forEach((lotterItem) => {
        let formData = {
          id: lotterItem.id,
          code: lotterItem.week,
          item: lotterItem.issue_num,
          matchCode: lotterItem.week + lotterItem.issue_num,
          comp: lotterItem.comp,
          home: lotterItem.home,
          away: lotterItem.away,
          pl: [],
          plv: [],
        };
        this.sellingList.forEach((sellItem) => {
          if (lotterItem.id == sellItem.id) {
            if (this.blendCode == 1) {
              // 足球
              if (sellItem.num != "") {
                let numText = "";
                if (sellItem.num < 0) {
                  numText = this.negativeMake(sellItem.name);
                } else {
                  numText = this.straightMake(sellItem.name);
                }
                let plVal = this.makeSpread(sellItem, numText);
                if (plVal) {
                  formData.pl.push(plVal);
                } else {
                  formData.pl.push(sellItem.value);
                }
              } else if (sellItem.title == "比分") {
                let plVal1 = this.straightScore(sellItem);
                formData.pl.push(plVal1);
              } else if (sellItem.title == "半全场") {
                let plVal2 = this.straightHalf(sellItem);
                formData.pl.push(plVal2);
              } else if (sellItem.title == "总进球") {
                let plVal3 = this.straightTotal(sellItem);
                formData.pl.push(plVal3);
              } else {
                formData.pl.push(sellItem.value);
              }
            } else {
              formData.pl.push(sellItem.value);
            }
          }
        });
        newArr.push(formData);
      });
      return newArr;
    },
    /* 让球的改变（-） */
    negativeMake(numText) {
      let text = "";
      switch (numText) {
        case "H":
          text = "H";
          break;
        case "D":
          text = "H";
          break;
        case "A":
          text = "DA";
          break;
      }
      return text;
    },
    /* 让球的改变（+） */
    straightMake(numText) {
      let text = "";
      switch (numText) {
        case "H":
          text = "HD";
          break;
        case "D":
          text = "A";
          break;
        case "A":
          text = "A";
          break;
      }
      return text;
    },
    /* 组合让分 */
    makeSpread(sellItem, numText) {
      let arr = this.target[sellItem.id];
      let plVal = "";
      arr.forEach((codeItem) => {
        if (numText.indexOf(codeItem.name) != -1 && codeItem.num == "") {
          plVal = Number(sellItem.value) + Number(codeItem.value);
        }
      });
      return plVal;
    },
    setNumRq(rq) {
        if (!rq) {
            return
        }
        let strArr = rq.split(',')
        let text = ''
        if (text != '-1' || text != '+1') {
            text = strArr[0]
        } else {
            text = ''
        }
        return text
    },
    /* 计算比分 */
    straightScore(sellItem) {
      let arr = this.target[sellItem.id];
      let plVal = 0;
      let rqText = this.setNumRq(sellItem.rq);
      let num = rqText.slice(1);
      if (sellItem.key == "胜其他") {
        let codeList = [];
        arr.forEach((codeItem) => {
          if (codeItem.title == "胜平负") {
            if (codeItem.name == "H") {
              plVal = plVal + Number(codeItem.value);
            }
          }
          if (codeItem.title == "让胜平负") {
            if (Number(rqText) < 1) {
              if (codeItem.name == "H" || codeItem.name == "D") {
                codeList.push(codeItem.value);
              }
            } else {
              if (codeItem.name == "H") {
                codeList.push(codeItem.value);
              }
            }
          }
        });
        if (codeList.length > 0) {
          let max = this.maxMoney(codeList);
          plVal = plVal + max;
        }
      } else if (sellItem.key == "平其他") {
        arr.forEach((codeItem) => {
          if (codeItem.title == "胜平负") {
            if (codeItem.name == "D") {
              plVal = plVal + Number(codeItem.value);
            }
          }

          if (codeItem.title == "让胜平负") {
            if (Number(rqText) < 1) {
              if (codeItem.name == "A") {
                plVal = plVal + Number(codeItem.value);
              }
            } else {
              if (codeItem.name == "H") {
                plVal = plVal + Number(codeItem.value);
              }
            }
          }
        });
      } else if (sellItem.key == "负其他") {
        let codeList = [];
        arr.forEach((codeItem) => {
          if (codeItem.title == "胜平负") {
            if (codeItem.name == "A") {
              plVal = plVal + Number(codeItem.value);
            }
          }

          if (codeItem.title == "让胜平负") {
            if (Number(rqText) < 1) {
              if (codeItem.name == "A" || codeItem.name == "D") {
                codeList.push(codeItem.value);
              }
            } else {
              if (codeItem.name == "A") {
                codeList.push(codeItem.value);
              }
            }
          }
        });
        if (codeList.length > 0) {
          let max = this.maxMoney(codeList);
          plVal = plVal + max;
        }
      } else {
        let scores = sellItem.key.split(":"); //获取比分
        let host = scores[0]; //主
        let guest = scores[1]; //客
        let hostBast = Number(host) - Number(guest);
        let guestBast = Number(guest) - Number(host);
        arr.forEach((codeItem) => {
          // 胜平负
          if (codeItem.title == "让胜平负") {
            if (rqText) {
              if (Number(rqText) < 0) {
                if (hostBast > num) {
                  if (codeItem.name == "H") {
                    plVal = Number(codeItem.value) + plVal;
                  }
                } else if (hostBast == num) {
                  if (codeItem.name == "D") {
                    plVal = Number(codeItem.value) + plVal;
                  }
                } else {
                  if (codeItem.name == "A") {
                    plVal = Number(codeItem.value) + plVal;
                  }
                }
              } else {
                if (guestBast > num) {
                  if (codeItem.name == "A") {
                    plVal = Number(codeItem.value) + plVal;
                  }
                } else if (hostBast == num) {
                  if (codeItem.name == "D") {
                    plVal = Number(codeItem.value) + plVal;
                  }
                } else {
                  if (codeItem.name == "H") {
                    plVal = Number(codeItem.value) + plVal;
                  }
                }
              }
            }
          }

          if (codeItem.title == "胜平负") {
            if (hostBast > 0) {
              if (codeItem.name == "H") {
                plVal = Number(codeItem.value) + plVal;
              }
            } else if (hostBast == 0) {
              if (codeItem.name == "D") {
                plVal = Number(codeItem.value) + plVal;
              }
            } else {
              if (codeItem.name == "A") {
                plVal = Number(codeItem.value) + plVal;
              }
            }
          }
        });
      }
      plVal = Number(sellItem.value) + Number(plVal);
      return plVal.toFixed(3);
    },
    /* 半全场 */
    straightHalf(sellItem, type) {
      let arr = this.target[sellItem.id];
      let halfText = sellItem.name.slice(1);
      let plVal = 0;
      let score = [];
      let num = this.setNumRq(sellItem.rq);
      let winList = [];
      let burList = [];

      arr.forEach((codeItem) => {
        if (halfText == "H") {
          if (codeItem.title == "胜平负") {
            if (codeItem.name == "H") {
              plVal = plVal + Number(codeItem.value);
            }
          }
          if (codeItem.title == "让胜平负") {
            if (Number(num) < 0) {
              if (codeItem.name == "H" || codeItem.name == "D") {
                winList.push(codeItem.value);
              }
            } else if (Number(num) > 0) {
              if (codeItem.name == "H") {
                plVal = plVal + Number(codeItem.value);
              }
            }
          }
        } else if (halfText == "D") {
          if (codeItem.title == "胜平负") {
            if (codeItem.name == "D") {
              plVal = plVal + Number(codeItem.value);
            }
          }
          if (codeItem.title == "让胜平负") {
            if (Number(num) < 0) {
              if (codeItem.name == "A") {
                plVal = plVal + Number(codeItem.value);
              }
            } else if (Number(num) > 0) {
              if (codeItem.name == "H" || codeItem.name == "D") {
                burList.push(codeItem.value);
              }
            }
          }
        } else if (halfText == "A") {
          if (codeItem.title == "胜平负") {
            if (codeItem.name == "A") {
              plVal = plVal + Number(codeItem.value);
            }
          }
          if (codeItem.title == "让胜平负") {
            if (Number(num) < 0) {
              if (codeItem.name == "A") {
                plVal = plVal + Number(codeItem.value);
              }
            } else if (Number(num) > 0) {
              if (codeItem.name == "H") {
                plVal = plVal + Number(codeItem.value);
              }
            }
          }
        }
        if (codeItem.title == "比分") {
          let scores = codeItem.key.split(":"); //获取比分
          let host = scores[0]; //主
          let guest = scores[1]; //客
          let hostBast = Number(host) - Number(guest);
          let guestBast = Number(guest) - Number(host);

          if (halfText == "H") {
            if (hostBast > 0) {
              score.push(codeItem.value);
            }
            if (codeItem.key == "胜其他") {
              score.push(codeItem.value);
            }
          } else if (halfText == "D") {
            if (hostBast == 0) {
              score.push(codeItem.value);
            }
            if (codeItem.key == "平其他") {
              score.push(codeItem.value);
            }
          } else if (halfText == "A") {
            if (hostBast < 0) {
              score.push(codeItem.value);
            }
            if (codeItem.key == "负其他") {
              score.push(codeItem.value);
            }
          }
        }
      });
      let scoreMax = 0;
      if (score.length > 0) {
        scoreMax = this.maxMoney(score);
      }
      if (winList.length > 0) {
        scoreMax = scoreMax + this.maxMoney(winList);
      }
      if (burList.length > 0) {
        scoreMax = scoreMax + this.maxMoney(burList);
      }
      let text = plVal + Number(sellItem.value) + Number(scoreMax);
      return text;
    },

    /* 总进球 */
    straightTotal(sellItem) {
      let plVal = 0;
      let arr = this.target[sellItem.id];
      let key = sellItem.key;
      let base = false;
      let baseNull = false;
      let numList1 = []; // 胜平负
      let numList2 = []; // 让胜平负
      let numList3 = []; // 比分
      let numList4 = []; // 半全场
      let keyList1 = [];
      let keyList2 = [];
      let keyList3 = [];
      let keyList4 = [];
      let maxVal1 = ""; // 胜平负 值
      let maxVal2 = ""; // 让胜平负 值
      let maxVal3 = ""; // 让胜平负 值
      let maxVal4 = ""; // 让胜平负 值
      let plValList = []; // 所有可能存在的集合最大值

      arr.forEach((codeItem) => {
        if (codeItem.title == "胜平负") {
          numList1.push(codeItem);
          keyList1.push(codeItem.value);
        }
        if (codeItem.title == "让胜平负") {
          numList2.push(codeItem);
        }
        if (codeItem.title == "比分") {
          numList3.push(codeItem);
        }
        if (codeItem.title == "半全场") {
          numList4.push(codeItem);
        }
      });

      if (key == "7+") {
        if (keyList1.length > 0) {
          if (keyList1.length > 0) {
            maxVal1 = this.maxMoney(keyList1);
          }
        }
        if (numList2.length > 0) {
          numList2.forEach((numItem) => {
            let letVal = this.stringhtLet(numItem);
            keyList2.push(letVal);
          });
          if (keyList2.length > 0) {
            maxVal2 = this.maxMoney(keyList2);
          }
        }
        if (numList3.length > 0) {
          numList3.forEach((numItem) => {
            if (
              numItem.key == "胜其他" ||
              numItem.key == "平其他" ||
              numItem.key == "负其他"
            ) {
              let scoVal = this.straightScore(numItem);
              keyList3.push(scoVal);
            } else {
              let scores = numItem.key.split(":"); //获取比分
              let host = scores[0]; //主
              let guest = scores[1]; //客
              let count = Number(host) + Number(guest);
              if (count >= 7) {
                let scoVal = this.straightScore(numItem);
                keyList3.push(scoVal);
              }
            }
          });
          if (keyList3.length > 0) {
            maxVal3 = this.maxMoney(keyList3);
          }
        }
        if (numList4.length > 0) {
          numList4.forEach((numItem) => {
            /* 在这里 */
            let halfVal = this.filterhalf(numItem, "half");
            keyList4.push(halfVal);
          });
          if (keyList4.length > 0) {
            maxVal4 = this.maxMoney(keyList4);
          }
        }
      } else {
        // 判断基数偶数
        if (Number(key) % 2 == 0) {
          if (key == "0") {
            let zeroVal = this.zeroHalf(
              sellItem,
              numList1,
              numList2,
              numList3,
              numList4
            );
            plValList.push(zeroVal);
          } else {
            let evenVal = this.evenHalf(
              sellItem,
              numList1,
              numList2,
              numList3,
              numList4,
              key
            );
            plValList.push(evenVal);
          }
          base = true;
        } else {
          let evenVal = this.oneHalf(
            sellItem,
            numList1,
            numList2,
            numList3,
            numList4,
            key
          );
          plValList.push(evenVal);
          /* 奇数 */
          base = false;
        }
      }
      if (maxVal1) {
        plValList.push(maxVal1);
      }
      if (maxVal2) {
        plValList.push(maxVal2);
      }
      if (maxVal3) {
        plValList.push(maxVal3);
      }
      if (maxVal4) {
        plValList.push(maxVal4);
      }
      let maxVal = 0;
      if (plValList.length > 0) {
        maxVal = this.maxMoney(plValList);
      }
      plVal = Number(sellItem.value) + maxVal;
      return plVal;
    },
    			/* 零的情况 */
			zeroHalf(sellItem,numList1,numList2,numList3,numList4) {
				let rqText = this.setNumRq(sellItem.rq)
				let num = rqText.slice(1)
				let zreoVals = []	// 符合条件的值
				let plaVal = 0
				if(numList1.length > 0) {
					numList1.forEach(numItem => {
						if(numItem.name == 'D') {
							zreoVals.push(numItem.value)
						}
					})
				}
				if(numList2.length > 0) {
					if(Number(rqText) < 0) {
						numList2.forEach(numItem => {
							if(numItem.name == 'A') {
								zreoVals.push(numItem.value)
							}
						})
					}else if(Number(rqText) > 0) {
						numList2.forEach(numItem => {
							if(numItem.name == 'H') {
								zreoVals.push(numItem.value)
							}
						})
					}else {
						numList2.forEach(numItem => {
							if(numItem.name == 'D') {
								zreoVals.push(numItem.value)
							}
						})
					}
				}
				if(numList3.length > 0) {
					numList3.forEach(numItem => {
						if(numItem.name == 'D0_0') {
							zreoVals.push(numItem.value)
						}
					})
				}
				if(numList4.length > 0) {
					numList4.forEach(numItem => {
						if(numItem.name == 'DD') {
							zreoVals.push(numItem.value)
						}
					})
				}
				let couVal = 0
				if(zreoVals.length > 0) {
					 zreoVals.forEach(couItemVal=> {
						couVal = Number(couItemVal) +  Number(couVal) 
					})
				}
				return couVal
			},
			/* 偶数的情况下 */
			evenHalf(sellItem,numList1,numList2,numList3,numList4,key) {
				let cunVal = this.statistics(numList1,numList2,numList3,numList4,key)
				return cunVal
			},
			/* 奇数的情况 */
			oneHalf(sellItem,numList1,numList2,numList3,numList4,key) {
				let cunVal = this.oddNumber(numList1,numList2,numList3,numList4,key,sellItem)
				return cunVal
			},
      			/* 统计偶数进球数组合情况 */
			statistics(numList1,numList2,numList3,numList4,key) {
				// 所有组合赔率
				let statisCounts = []
				let scoreVals = []		// 比分符合条件的组合
				let letVals = []		// 让胜平负符合条件的组合
				let courtCal = []		// 半全场符合条件的组合
				/* 胜平负 */
				if(numList1.length > 0) {
					numList1.forEach(numItem => {
						statisCounts.push(numItem.value)
					})
				}
				/* 让胜平负 */
				if(numList2.length > 0) {
					numList2.forEach(numItem => {
						if(numItem.name == 'H' || numItem.name == 'A') {
							letVals.push(numItem)
						}
					})
				}
				/* 比分 */
				if(numList3.length > 0) {
					numList3.forEach(numItem => {
						if(numItem.key != '平其他' && numItem.key != '负其他' && numItem.key != '胜其他') {
							let scores = numItem.key.split(':') //获取比分
							let host = scores[0] //主
							let guest = scores[1] //客
							let hostCon = Number(host) + Number(guest)
							// 判断比分的值是否相等
							if(hostCon == key) {
								scoreVals.push(numItem)
							}
						}
					})
				}
				/* 半全场 */
				if(numList4.length > 0) {
					numList4.forEach(numItem => {
						if(key == '2') {
							if(numItem.name != 'HA') {
								courtCal.push(numItem)
							}
						}else {
							courtCal.push(numItem)
						}
						
					})
				}
				/* 得到所有符合条件的组合 */
				let fuitVals = [...numList1,...scoreVals,...letVals,...courtCal]
				let newArr = this.consusVal(fuitVals)
				if(newArr.length > 0) {
					return this.maxMoney(newArr)
				}else {
					return 0
				}
			},
      			/* 统计奇数进球数组合情况 */
			oddNumber(numList1,numList2,numList3,numList4,key,sellItem) {
				let rqText = this.setNumRq(sellItem.rq)
				let num = rqText.slice(1)
				
				let statisCounts = []	// 胜平负符合条件的组合
				let scoreVals = []		// 比分符合条件的组合
				let letVals = []		// 让胜平负符合条件的组合
				let courtCal = []		// 半全场符合条件的组合
				
				/* 胜平负 */
				if(numList1.length > 0) {
					numList1.forEach(numItem => {
						if(numItem.name != 'D') {
							statisCounts.push(numItem)
						}
					})
				}
				
				/* 让胜平负 */
				if(numList2.length > 0) {
					numList2.forEach(numItem => {
							if(Number(rqText)  < 0) {
								if(numItem.name != 'D') {
									if(Number(key) > Number(num) ) {
										scoreVals.push(numItem)
									}else {
										if(numItem.name != 'H') {
											scoreVals.push(numItem)
										}
									}
								}
							}else {
								if(numItem.name != 'A') {
									scoreVals.push(numItem)
								}
							}
					})
				}
				/* 比分 */
				if(numList3.length > 0) {
					numList3.forEach(numItem => {
						if(numItem.key != '平其他' && numItem.key != '负其他' && numItem.key != '胜其他') {
							let scores = numItem.key.split(':') //获取比分
							let host = scores[0] //主
							let guest = scores[1] //客
							let hostCon = Number(host) + Number(guest)
							// 判断比分的值是否相等
							if(hostCon == key) {
								scoreVals.push(numItem)
							}
						}
					})
				}
				
				/* 半全场 */
				if(numList4.length > 0) {
					numList4.forEach(numItem => {
						if(key == '1') {
							if(numItem.name != 'HA' || numItem.name != 'AH') {
								courtCal.push(numItem)
							}
						}else {
							courtCal.push(numItem)
						}
						
					})
				}
				
				/* 得到所有符合条件的组合 */
				let fuitVals = [...statisCounts,...scoreVals,...letVals,...courtCal]
				let newArr = this.consusVal(fuitVals)
				if(newArr.length > 0) {
					return this.maxMoney(newArr)
				}else {
					return 0
				}
			},
      /* 从新统计符合条件的组合 */
			consusVal(fuitVals) {
				let consus = []
				if(fuitVals.length > 0) {
					fuitVals.forEach(sellItem => {
						if (sellItem.num != '') {
							let numText = ''
							if (sellItem.num < 0) {
								numText = this.negativeMake(sellItem.name)
							} else {
								numText = this.straightMake(sellItem.name)
							}
							let plVal = this.makeSpread(sellItem, numText)
							if (plVal) {
								consus.push(plVal)
							} else {
								consus.push(sellItem.value)
							}
						} else if (sellItem.title == '比分') {
							let plVal1 = this.straightScore(sellItem)
							consus.push(plVal1)
						} else if (sellItem.title == '半全场') {
							let plVal2 = this.straightHalf(sellItem)
							consus.push(plVal2)
						}else {
							consus.push(sellItem.value)
						}
					})
				}
				return consus
			},
    /* 计算最高奖金 */
    maximum() {
      let newArr = [];
      this.lotteryList.forEach((lotterItem) => {
        // dataArr.push(item)
        let arr = this.target[lotterItem.id];
        let dataArr = [];
        arr.forEach((item) => {
          let val = item.name + "," + item.value;
          if (item.num) {
            val = val + "," + item.num;
          }
          dataArr.push(val);
        });
        newArr.push(dataArr);
      });
      return newArr;
    },
    /* 
        提交订单并调用打印模版
    */
    async onSubmit() {
      await this.setFilteCode()
      let sedFlat = false
      if(this.isComplex == 1) {
        this.reachType = '自由过关'
        sedFlat = true
        let makeTest = []
        this.selectMakeList.forEach(makeItem => {
          makeTest.push(makeItem.selectVal)
        })
        this.selectedValue = makeTest.join(',')
      }else {
        this.reachType = 'M串N'
        sedFlat = false
        this.selectedValue = this.selectText
      }
      this.setPrintData(sedFlat)
    },
    setPrintData(sedFlat) {
      const lotteryProceeds = this.price * 0.21
      // 过关方式
      const printParameter = {
        type: 1,
        game_type: this.gameType,
        random_number: this.printForm.random_number,              // 顶部编码1
        random_numberT: this.printForm.random_numberT,            // 顶部编码2
        randomString: this.printForm.randomString,                // 顶部编码3
        selectedValue: sedFlat ? this.selectedValue : '',                         // 过关方式
        multiple: this.dataNumber,                                // 倍数
        Betamount: this.price,                                    // 合计金额
        combinationboun: this.purchase_amount,            // 最高奖金
        combinationCount: this.note,           // 注数
        lotteryProceeds,                   // 公益贡献金额
        generatedNumber: this.printForm.generatedNumber,
        currentDate: getNowTime('base'),
        lottery_add: this.printForm.lottery_add,
        order_time: getNowTime(),
        print_time: getNowTime(),
        bet_money: this.price,
        customer_name: this.customerName,
        reach_type: this.reachType,
        note: this.note,
        // Clearance_mode: sedFlat ? '' : this.selectedValue,
        Clearance_mode: sedFlat ? '' : this.selectedValue,
        lottery: JSON.stringify(this.sellingList),
        order_status: 1,
        after_lottery: JSON.stringify(this.afterLottery),
        sellingNumber: this.sellingNumber

      }
      // 差提交接口
      this.loading = true
      contestLotterys(printParameter).then((res) => {
      this.loading = false
        if(res.code == 200) {
            this.$message({
                type: "success",
                message: res.msg,
            });
            this.switchNavIndex(this.navIndex)
        }
      })

      getCodingGain({
        type: 1,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          this.printForm = res.data
        }
      })
    },
    cleanSoccerVal() {
				this.tableData.forEach(infoItem => {
						infoItem.selectNum = 0
						if (this.ifType(infoItem.data)) {
							infoItem.data.forEach((codeItem, index) => {
                if (index == 2) {
                  codeItem.data.forEach(item => {
										if (this.ifType(item.data)) {
											item.data.forEach(basItem => {
												if (basItem.status == 1) {
													basItem.status = 0
												}
											})
										} else {
											if (!this.ifType(item.data)) {
												if (item.data.status == 1) {
													item.data.status = 0
												}
											}
										}
									})
                }else {
                  if (this.ifType(codeItem.data)) {
                    codeItem.data.forEach(item => {
                      if (item.status == 1) {
                        item.status = 0
                      }
                    })
                  }
                }
							})
						}
				})
        this.sellingNumber = 0
        this.sessionNum = 0
        this.dataNumber = 1
        this.printNum = 1
        this.target = []
        this.lotteryList = []
        this.sellingList = []
        this.purchase_amount = 0
        this.price = 0
        this.singleList = []
        this.multipleList = []
			},
      /* 判断类型 */
			ifType(type) {
				let flat = false
				if (type instanceof Array) {
					flat = true
				} else {
					flat = false
				}
				return flat
			},
    // 展开 收取
    clickRowHandle(row) {
      if (this.expands.includes(row.id)) {
        row.expandsStatus = 0
        this.expands = this.expands.filter(val => val !== row.id);
      } else {
        row.expandsStatus = 1
        this.expands.push(row.id);
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-table__expanded-cell {
  padding: 0 !important;
}
@import url("../../assets/css/always.css");

.single_f {
  background: #f53629 !important;
  color: #fff !important;
}
/deep/ .el-button {
  margin-left: 0 !important;
  // border-radius: 0 !important;
}

.outer_content {
  height: calc(100% - 212px);
  overflow-y: auto;
}
/deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
	display: none;
  width: 0;
}

.one_text {
  margin-left: 15px;
  font-size: 15px;
  color: orange;
}

.gird_item {
  border-radius: 0 !important;
}

.odds_key {
  font-size: 14px;
  font-weight: bold;
}

</style>
